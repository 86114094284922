import React from 'react';
import Portfolio from './Portfolio';
import data from '../data/work.json';
import banner from '../assets/images/works.svg';

export default function Works() {
  return (
    <Portfolio
      title="works."
      icon="pen"
      data={data}
      banner={banner}
      description=" Selected professional works that have been created in the recent years, ranging from branding, art
  direction, illustration, and motion graphics, bringing vision to life."
    />
  );
}
