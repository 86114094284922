import React from 'react';
import Portfolio from './Portfolio';
import data from '../data/fun-stuff.json';
import banner from '../assets/images/fun-stuff.svg';

export default function FunStuff() {
  return (
    <Portfolio
      title="fun stuff."
      icon="smile"
      data={data}
      banner={banner}
      description="A collection of my passion projects consisting of whimsical illustration and animation with a swirl of mythical creatures, fantastical stories, cultures, and everyday life."
    />
  );
}
